<template>
  <v-dialog v-model="showModal" scrollable width="1400px" persistent>
    <v-form ref="form" v-model="valid">
      <v-card class="card-rounded-bottom" style="min-height: 600px">
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         :text="`${currentPackage.id!=null ? 'Update Package':'Add Package'}`" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <v-col cols="12" sm="4" md="4">
              <label for="">Package Name</label>
              <v-text-field
                  v-model="currentPackage.name"
                  outlined
                  required
                  :rules="[(v) => !!v || 'Package name is required']"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              ></v-text-field>
            </v-col>
          </div>

          <div class="benefit-bar py-4 px-2 ml-2">
            <p class="mb-0">Benefits</p>
          </div>

          <v-row v-for="(product,ind) in currentPackage.products" :key="ind" class="py-4 px-2 my-0 relative"
          @mouseover="mouseOverEvent(ind)"
          @mouseleave="mouseLeaveEvent(ind)"
          >
            <v-col class="same-width">
              <label for="">Venue </label>
              <v-select
                  v-model="product.venueId"
                  :items="venues"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Venue is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProductTypes(ind)"
              >
              </v-select>
            </v-col>
            <v-col class="same-width">
              <label for="">Product Type </label>
              <v-select
                  v-model="product.productType"
                  :items="product.productTypes"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Product Type is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="loadTypeData(ind)"
              >
              </v-select>
            </v-col>
            <v-col class="same-width" v-if="getName(ind) !== 'POS'">
              <label for="">{{ getName(ind) }}</label>
              <v-select
                  v-model="product.event_id"
                  v-if="getName(ind) == 'Event'"
                  :items="product.events"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Event is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.trainer_id"
                  v-else-if="getName(ind) == 'Trainer'"
                  :items="product.trainers"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Trainer is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.membership_id"
                  v-else-if="getName(ind) == 'Membership'"
                  :items="product.memberships"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Membership is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.facility_id"
                  v-else-if="getName(ind) == 'Facility'"
                  :items="product.facilities"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Facility is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.workshop_id"
                  v-else-if="getName(ind) == 'Academy'"
                  :items="product.academies"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Academy is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.workshop_id"
                  v-else
                  :items="[]"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Choose product type']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
              >
              </v-select>
            </v-col>
            <v-col class="same-width">
              <label for="">Product </label>
              <v-select
                  v-model="product.product_id"
                  :items="product.products"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Product is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getPrices(ind,true)"
              >
              </v-select>
            </v-col>
            <v-col class="same-width">
              <label for="">Price (Inc. VAT)</label>
              <v-text-field
                  v-model="product.calculated_price"
                  outlined
                  readonly
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  :prefix="currencyCode"
                  background-color="#fff"
                  dense
              ></v-text-field>
            </v-col>
            <v-col class="same-width">
              <label for="">Quantity</label>
              <v-text-field
                  v-model="product.quantity"
                  outlined
                  :readonly="!!product.restrict_quantity"
                  :rules="[
                    (v) => {
                      if (v > 0) {
                        return true;
                      }else if(v === 0){
                        return 'Quantity cannot be zero'
                      }
                      return 'Enter Quantity';
                    },
                  ]"
                  @change="changePrice(ind)"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  background-color="#fff"
                  dense
              ></v-text-field>
            </v-col>
            <v-col class="same-width">
              <label for="">Offer Price (Inc. VAT)</label>
              <v-text-field
                  v-model="product.offer_price"
                  outlined
                  required
                  :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Offer Price is required';
                    },
                  ]"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  :prefix="currencyCode"
                  background-color="#fff"
                  dense
                  @change="calculateOfferPrice"
              ></v-text-field>
            </v-col>
            <v-btn
                v-if="product.hover && currentPackage.products.length > 0"
                x-small
                color="white"
                class="small mt-4"
                absolute
                right
                icon
                @click="deleteProduct(ind)"
            >
              <DeleteIcon/>
            </v-btn>
          </v-row>

          <v-col>
            <v-btn
                class="teal-color"
                @click="addNewPackage"
                dark
                elevation="0"
                style="font-weight: 600"
            >
              <v-icon left dark>mdi-plus</v-icon>
              Add More Products
            </v-btn>
          </v-col>

        </v-card-text>

        <v-card-actions class="card-footer">
          <div class="d-flex justify-space-between ml-4" style="width: 100%">
            <div class="d-flex justify-space-between align-center">
              <span class="text_before_price">Total Price: {{ getTotalPrice() | toCurrency }}   | Offer Price </span>
              <v-text-field
                  v-model="currentPackage.offerPrice"
                  outlined
                  required
                  readonly
                  :rules="[(v) => !!v || 'Offer price is required']"
                  class="q-text-field shadow-0 mx-2"
                  hide-details="auto"
                  :prefix="currencyCode"
                  background-color="#fff"
                  dense
              ></v-text-field>
              <span class="text_after_price">
              Including VAT
            </span>
            </div>
            <div>
              <v-btn
                  class="ma-2 "
                  text
                  @click="close"
              >Close
              </v-btn
              >

              <v-btn
                  elevation="0"
                  class="ma-2 white--text blue-color" @click="saveProduct"
              >{{ currentPackage.id != null ? "Update" : "Save" }}
              </v-btn
              >
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>

</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";

export default {
  name: "PackageModal",
  components: {DeleteIcon, SvgIcon},
  watch: {
    refreshForm(){
      this.currentPackage = {
        id: null,
        name: null,
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          quantity:1,
          restrict_quantity:0,
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          price: null,
          calculated_price: null,
          offer_price:null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          workshop_id: null,
          hover:false,
        }],
        offerPrice: 0,
      };
      this.deleted_package_product_id = [];
    },
    editPackage(val) {
      if (val !== null) {
        let prod = val.package_products
        this.currentPackage = {
          id:val.id,
          name: val.package_name,
          products: JSON.parse(JSON.stringify(prod)),
          offerPrice: val.offer_price,
        }


        this.currentPackage.products.forEach(async (p,ind) => {
          await this.getProductTypes(ind)
          await this.loadTypeData(ind)
          await this.getProducts(ind)
          this.getPrices(ind)
        })

      }
    },
    id(val) {
      if (val != null) {
        this.getPackageDetails();
      } else {
        this.currentPackage = {
          id: null,
          name: null,
          products: [{
            productTypes:[],
            products:[],
            events:[],
            trainers:[],
            memberships:[],
            facilities:[],
            academies:[],
            venueId: null,
            productType: null,
            product_id: null,
            name: null,
            price: null,
            quantity:1,
            restrict_quantity:0,
            offer_price:null,
            event_id: null,
            trainer_id: null,
            membership_id: null,
            facility_id: null,
            workshop_id: null,
            hover:false,
          }],
          offerPrice: 0,
        };
      }
    },
  },
  props: {
    refreshForm:{type: Boolean, default: false},
    showModal: {type: Boolean, default: false},
    id: {type: Number, default: null},
    editPackage: {type: Object, default: null},
  },
  computed: {
    venues() {
      return this.$store.getters.userVenues;
    },
  },
  data() {
    return {
      deleted_package_product_id: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      currentPackage: {
        id: null,
        name: null,
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          price: null,
          calculated_price: null,
          offer_price:null,
          event_id: null,
          trainer_id: null,
          quantity:1,
          restrict_quantity:0,
          membership_id: null,
          facility_id: null,
          workshop_id: null,
          hover:false,
        }],
        offerPrice: 0,
      },
      valid: false,
    }
  },
  methods: {
    changePrice(index){
      this.currentPackage.products[index].calculated_price =  this.currentPackage.products[index].price * this.currentPackage.products[index].quantity;
      this.$forceUpdate()
    },
    async getProductTypes(index){
      this.currentPackage.products[index].productTypes = [];
      this.currentPackage.products[index].facilities = [];
      this.currentPackage.products[index].events = [];
      this.currentPackage.products[index].trainers = [];
      this.currentPackage.products[index].memberships = [];
      this.currentPackage.products[index].academies = [];
      this.currentPackage.products[index].products = [];

      this.showLoader("Loading product Types..");
      await this.$http.get(`venues/offers/packages/get-product-types/${this.currentPackage.products[index].venueId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              let permissions = response.data.data.permissions;

              this.currentPackage.products[index].productTypes = Object.values(permissions)
                  .filter(permission => permission.allowed === 1)
                  .map(permission => ({
                    id: permission.id,
                    name: permission.name
                  }));

            }else{
              this.currentPackage.products[index].productTypes = [];
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    async loadTypeData(index){
      if(this.currentPackage.products[index].productType === 9){
        return this.getProducts(index);
      }
      this.currentPackage.products[index].facilities = [];
      this.currentPackage.products[index].events = [];
      this.currentPackage.products[index].trainers = [];
      this.currentPackage.products[index].memberships = [];
      this.currentPackage.products[index].academies = [];
      this.currentPackage.products[index].products = [];

      this.showLoader("Loading product Types..");
      await this.$http.get(`venues/offers/packages/get-type-data/${this.currentPackage.products[index].venueId}/${this.currentPackage.products[index].productType}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.currentPackage.products[index].facilities = response.data.data.facilities;
              this.currentPackage.products[index].events = response.data.data.events;
              this.currentPackage.products[index].trainers = response.data.data.trainers;
              this.currentPackage.products[index].memberships = response.data.data.memberships;
              this.currentPackage.products[index].academies = response.data.data.academies;
              this.$forceUpdate()
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    async getProducts(index){
      this.currentPackage.products[index].products = [];
      let filter = this.currentPackage.products[index].productTypes.find(i => i.id === this.currentPackage.products[index].productType);
      let obj = this.currentPackage.products[index];
      let typeId = 0;

      if(filter.name === 'Event'){
        typeId = obj.event_id
      }
      if(filter.name === 'Trainer'){
        typeId = obj.trainer_id
      }
      if(filter.name === 'Membership'){
        typeId = obj.membership_id
      }
      if(filter.name === 'Facility'){
        typeId = obj.facility_id
      }
      if(filter.name === 'Academy'){
        typeId = obj.workshop_id
      }

      this.showLoader("Loading products");
      await this.$http.get(`venues/offers/packages/get-products/${this.currentPackage.products[index].venueId}/${this.currentPackage.products[index].productType}/${typeId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.currentPackage.products[index].products = response.data.data;
              let quantity = this.currentPackage.products[index].quantity;
              this.currentPackage.products[index].quantity = quantity ?? 1;
              this.currentPackage.products[index].restrict_quantity = response.data.restrict_quantity;
            }else{
              this.currentPackage.products[index].products = [];
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.$forceUpdate()
        this.hideLoader()
      })
    },
    mouseOverEvent(index){
      this.currentPackage.products[index].hover=true
      this.$forceUpdate()
    },
    mouseLeaveEvent(index){
      this.currentPackage.products[index].hover=false
      this.$forceUpdate()
    },
    calculateOfferPrice() {
      let sum = this.currentPackage.products.reduce((acc, product) => {
        return acc + parseFloat(product.offer_price) || 0;
      }, 0);

      // Use toFixed to round off the sum to the desired number of decimal places, e.g., 2
      this.currentPackage.offerPrice = parseFloat(sum.toFixed(2));
    },
    getPrices(index,changePrice = false){
      let product = this.currentPackage.products[index].products.find(i => i.id === this.currentPackage.products[index].product_id);
      let offer_price = this.currentPackage.products[index].offer_price;
      let quantity = this.currentPackage.products[index].quantity;
      this.currentPackage.products[index].price = product.price;

      this.currentPackage.products[index].calculated_price = product.price * (quantity ?? 1);
      if(changePrice){
        this.currentPackage.products[index].offer_price = product.price;
      }else{
        this.currentPackage.products[index].offer_price = offer_price ?? product.price;
      }
      this.$forceUpdate();
      this.calculateOfferPrice();
    },
    getTotalPrice() {
      let sum = 0;
      this.currentPackage.products.forEach(product => {
        sum += product.price * product.quantity;
      });
      return sum;
    },
    addNewPackage() {
      this.currentPackage.products.push({
        productTypes:[],
        products:[],
        events:[],
        trainers:[],
        memberships:[],
        facilities:[],
        academies:[],
        venueId: null,
        productType: null,
        product_id: null,
        quantity:1,
        restrict_quantity:0,
        name: null,
        price: null,
        calculated_price: null,
        offer_price:null,
        event_id: null,
        trainer_id: null,
        membership_id: null,
        facility_id: null,
        workshop_id: null,
        hover:false,

      })
    },
    getProductsOld(product_type_id) {
      return this.products.filter((ele) => {
        return ele.product_type_id === product_type_id
      })
    },
    getName(index) {
      let name = 'Type';
      if (this.currentPackage.products[index].productType) {
        let find = this.currentPackage.products[index].productTypes.find(e => e.id === this.currentPackage.products[index].productType);
        if (find) {
          return find.name;
        }
      }
      return name;
    },
    getPackageDetails() {
      console.log('fetching');
    },
    confirmActions(data) {
      console.log(data);
    },
    close() {
      this.currentPackage = {
        id: null,
        name: null,
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          quantity:1,
          restrict_quantity:0,
          productType: null,
          product_id: null,
          name: null,
          price: null,
          calculated_price: null,
          offer_price:null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          workshop_id: null,
          hover:false,
        }],
        offerPrice: 0,
      };
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
    deleteProduct(i) {
      if(this.currentPackage.products[i].package_product_id){
        this.deleted_package_product_id.push(this.currentPackage.products[i].package_product_id);
      }
      this.currentPackage.products.splice(i, 1);
      this.calculateOfferPrice();
    },
    saveProduct() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.currentPackage));

      data.products.map((ele) => {
        delete ele.academies;
        delete ele.facilities;
        delete ele.trainers;
        delete ele.events;
        delete ele.products;
        delete ele.memberships;
        delete ele.productTypes;
        delete ele.hover;
      });

      if(this.deleted_package_product_id.length > 0){
        data.deleted_package_product_id = this.deleted_package_product_id;
      }

      this.$emit('save', data);

      this.$refs.form.resetValidation();
    }
  }
}
</script>
<style lang="scss">
.benefit-bar {
  background: rgba(79, 174, 175, 0.05);
  color: rgba(79, 174, 175, 1);
  font-weight: 600;
}

.card-rounded-bottom {
  border-radius: 10px !important;
}

.card-footer {
  background: rgba(248, 250, 251, 1);
}

.text_before_price {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}

.text_after_price {
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;

}

.same-width{
  max-width:14.28% !important;
}
</style>