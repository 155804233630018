<template>
    <v-card style="border-radius: 8px" class="shadow package_card p-0">
      <v-card-text class=" p-0">
        <div class="p-4">
          <div  class="mt-2 pt-0 pb-1 height-text">
            <p class="property_name mb-0">Venue</p>
            <p class="property_value mt-0 mb-1 line-clamp-1">{{ product.venueName }}</p>
          </div>

          <div  class="mt-1 py-0 pb-1 height-text">
            <p class="property_name mb-0">Type</p>
            <p class="property_value mt-0 mb-1">{{ product.productTypeName ? product.productTypeName : product.productType }}</p>
          </div>

          <div  class="mt-1 py-0 height-text">
            <p class="property_name mb-0">Product</p>
            <p class="property_value mt-0 mb-2">{{ product.name }}</p>
          </div>
        </div>

          <div class="d-flex justify-center align-center nth_card_footer py-2" style="width: 100%">{{ (product.price * product.quantity) | toCurrency }}</div>

      </v-card-text>

    </v-card>

</template>
<script>
// import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "Package.vue",
  // components: {SvgIcon},
  watch: {
    id(val) {
      if (val != null) {
        this.getPackageDetails();
      }
    },
  },
  props: {
    product: {type: Object, default: () => {}},
    index: {type: Number, default: 0},
  },
  data() {
    return {
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },

      valid: false,
    }
  },
  methods: {}
}
</script>
<style lang="scss">

.package_card {
  width: 220px;
  min-height: 175px;
  top: 225px;
  left: 507px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  position: unset;

  .property_name {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;

  }

  .property_value {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
  }

  .height-text {
    color: black;
    min-height: 30px;
  }

  .nth_card_footer {
    background: rgba(17, 42, 70, 0.05);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>